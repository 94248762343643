<template>
  <div class="main">
    <section class="top">
      <div class="slogan flex">
        <span><em>PowerMars</em>储能式充电系统</span>
        <span class="s">实力登场</span>
      </div>
      <div class="img">
        <el-image :src="bg_one" fit="contain"></el-image>
      </div>
    </section>

    <section class="cards">
      <div class="line flex">
        <div class="card one">
          <div class="over">
            <div class="title scroll-fade" v-scrollFade="'active'">
              DCDC高效率充电模块<br />
              最高实现190kW输出
            </div>
            <div class="subtitle scroll-fade" v-scrollFade="'active'">
              突破变压器限制，轻松达到超充功率<br />
              省时，省心，高效
            </div>
          </div>
        </div>
        <div class="card one">
          <div class="over">
            <div class="title scroll-fade" v-scrollFade="'active'">
              EMS能源管理系统<br />
              智能调度
            </div>
            <div class="subtitle scroll-fade" v-scrollFade="'active'">
              动态分配更智能，根据充电车辆情况实时分配<br />
              充电功率从而达到效率与稳定的平衡
            </div>
          </div>
        </div>
      </div>
      <div class="line flex">
        <div class="card two">
          <div class="over">
            <div class="title scroll-fade" v-scrollFade="'active'">
              高倍率LFP电芯<br />
              1C充电 2C放电
            </div>
            <div class="subtitle scroll-fade" v-scrollFade="'active'">
              随时可充，随时可用<br />
              最快一小时即可充满电池
            </div>
          </div>
        </div>
        <div class="right">
          <div class="card three">
            <div class="over">
              <div class="title scroll-fade" v-scrollFade="'active'">
                集成光伏输入<br />
                满足不同场景需求
              </div>
              <div class="subtitle scroll-fade" v-scrollFade="'active'">
                光储好搭档，削峰填谷好帮手<br />
                进一步降低场充电成本
              </div>
            </div>
          </div>
          <div class="card three">
            <div class="over">
              <div class="title scroll-fade" v-scrollFade="'active'">
                削峰填谷<br />
                充电收益最大化
              </div>
              <div class="subtitle scroll-fade" v-scrollFade="'active'">
                谷充峰放好便利<br />
                平充峰放添收益
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line flex">
        <div class="card four">
          <div class="over">
            <div class="title scroll-fade" v-scrollFade="'active'">
              车规级安全标准
            </div>
            <div class="subtitle scroll-fade" v-scrollFade="'active'">
              从电芯到系统<br />
              百项安全测试只为用的放心
            </div>
          </div>
        </div>
        <div class="card four cseven">
          <div class="over">
            <div class="title scroll-fade" v-scrollFade="'active'">
              快速灵活部署<br />
              一插即用
            </div>
            <div class="subtitle scroll-fade" v-scrollFade="'active'">
              出厂预调试，到场即安装<br />
              模块化设计，快速投入使用
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="wall"></section>

    <section class="digital flex">
      <div class="item flex">
        <div class="nums scroll-fade" v-scrollFade="'active'">
          <span class="big">160</span>
          <span class="small">kWh</span>
        </div>
        <div class="btm">最大电池容量</div>
      </div>
      <div class="item flex">
        <div class="nums scroll-fade" v-scrollFade="'active'">
          <span class="big">190</span>
          <span class="small">kW </span>
        </div>
        <div class="btm">最大输出功率</div>
      </div>
      <div class="item flex">
        <div class="nums scroll-fade" v-scrollFade="'active'">
          <span class="big">1000</span>
          <span class="small">V </span>
        </div>
        <div class="btm">最高输出电压</div>
      </div>
    </section>

    <section class="first cnt">
      <div class="h1 scroll-fade" v-scrollFade="'active'">
        满足未来能源需求的全新系统
      </div>

      <div class="area flex">
        <div class="left">
          <div class="h2-box flex scroll-fade" v-scrollFade="'active'">
            <div class="h2">更少的电力输入</div>
            <div class="h2">更快的高功率输出</div>
          </div>
          <div class="h3 scroll-fade" v-scrollFade="'active'">
            只需要电网提供30kW或者更少的负荷余量，就可以实现最大190KW的输出功率给电动汽车充电。
          </div>
        </div>
        <div class="right"></div>
      </div>
    </section>

    <section class="snd cnt">
      <div class="h1 scroll-fade" v-scrollFade="'active'">
        EMS能源管理系统，智能调度
      </div>
      <div class="bg"></div>
    </section>

    <section class="three cnt">
      <div class="h1 scroll-fade" v-scrollFade="'active'">
        高倍率LFP电芯，实现2C放电
      </div>

      <div class="area flex">
        <div class="left">
          <div class="h2-box flex scroll-fade" v-scrollFade="'active'">
            <div class="h2">超长寿命更耐用</div>
            <div class="h2">持续输出更稳定</div>
          </div>
          <div class="h3 scroll-fade" v-scrollFade="'active'">
            输出功率最高可达190kW，放电总时长
            ≤30mins，轻松满足新能源汽车日益增大的充电功率需求。
          </div>
        </div>
        <div class="right"></div>
      </div>
    </section>

    <section class="cnt">
      <div class="h1 scroll-fade" v-scrollFade="'active'">
        集成光伏输入，满足不同场景需求
      </div>

      <div class="area flex">
        <div class="left">
          <div class="h2-box flex scroll-fade" v-scrollFade="'active'">
            <div class="h2">光储节能好搭档</div>

            <div class="h2">电能价差收益高</div>
          </div>
          <div class="h3 scroll-fade" v-scrollFade="'active'">
            搭配ACDC变流器接入光伏后进一步降低了储能的用电成本，利用光伏所产生的电量给新能源车充电，赚取更多的电价收益。
          </div>
        </div>
        <div class="right"></div>
      </div>
    </section>

    <section class="cnt">
      <div class="h1 scroll-fade" v-scrollFade="'active'">
        削峰填谷，应用场景多样化
      </div>
      <div class="area flex" style="flex-flow: row-reverse">
        <div class="left">
          <div class="h2-box flex scroll-fade" v-scrollFade="'active'">
            <div class="h2">谷充峰放好便利</div>
            <div class="h2">平充峰放添收益</div>
          </div>
          <div class="h3 scroll-fade" v-scrollFade="'active'">
            电价谷时从电网购买低价电能，充分利用高倍率电芯的优势，加大削峰填谷所带来的经济收益。
          </div>
        </div>
        <div class="right" style="height: 54.5rem"></div>
      </div>

      <div class="area flex" style="margin-top: 10.5rem">
        <div class="left">
          <div class="h2-box flex lineone">
            <div class="h2 scroll-fade" v-scrollFade="'active'">需求侧响应</div>
          </div>
          <div class="h3 scroll-fade" v-scrollFade="'active'">
            配合ACDC模块，在未来接入电网系统之后，
            参与电网调度，加强电网稳定性的同时，创造自身的另一份收益。
          </div>
        </div>
        <div class="right" style="height: 54.5rem"></div>
      </div>
    </section>

    <section class="tags flex">
      <div class="tag flex">需求侧响应</div>
      <div class="tag flex">削峰填谷</div>
      <div class="tag flex">备用电源</div>
      <div class="tag flex">双枪输出</div>
    </section>

    <section class="cnt">
      <div class="h1 scroll-fade" v-scrollFade="'active'">
        车规级安全保障，全面防护
      </div>
      <div class="area flex" style="flex-flow: row-reverse">
        <div class="left">
          <div class="h2-box flex lineone">
            <div class="h2 scroll-fade" v-scrollFade="'active'">精准预警</div>
          </div>
          <div class="h3 scroll-fade" v-scrollFade="'active'">
            通过热失控预警器,实时监测烟雾、温度、可燃
            气体(一氧化碳、氢气、VOC),达到预警值时,安全系统自动启动声光报警器,发送警示到誉照云平
            台,及时提醒相关人员;水浸检测达预警值时,自动
            触发系统停机,推送故障信息至云平台。
          </div>
        </div>
        <div class="right" style="height: 54.5rem"></div>
      </div>

      <div class="area flex" style="margin-top: 10.5rem">
        <div class="left">
          <div class="h2-box flex lineone">
            <div class="h2 scroll-fade" v-scrollFade="'active'">主动介入</div>
          </div>
          <div class="h3 scroll-fade" v-scrollFade="'active'">
            PowerMars基于热失控发生前主动介入、热失控影响最小化的安全设计思路，推出"预警、泄爆、消防”三层系统安全设计，减少安全故
            障损失。
          </div>
        </div>
        <div class="right" style="height: 54.5rem"></div>
      </div>

      <div
        class="area flex"
        style="margin-top: 10.5rem; flex-flow: row-reverse"
      >
        <div class="left">
          <div class="h2-box flex lineone">
            <div class="h2 scroll-fade" v-scrollFade="'active'">及时消防</div>
          </div>
          <div class="h3 scroll-fade" v-scrollFade="'active'">
            电池包内配置全氟已酮气体消防,舱体内配置系
            统级水、气体消防,一旦发生热失控,系统快速启动
            保护,即刻扑灭热失控。此外,舱壁依据GB/T 9978
            标准，满足最高2小时耐火,阻止热失控蔓延，减少对周边设备影响,争取消防时间。
          </div>
        </div>
        <div class="right" style="height: 54.5rem"></div>
      </div>
    </section>

    <section class="four cnt">
      <div class="h1 scroll-fade" v-scrollFade="'active'">
        PowerMars适合投放场景
      </div>
      <div class="h3 scroll-fade" v-scrollFade="'active'">
        只需要电网提供30kW或者更少的负荷余量，就可以实现最大190KW的输出功率给电动汽车充电。
      </div>
    </section>

    <section class="tags flex">
      <div class="tag flex">加油站</div>
      <div class="tag flex">服务区</div>
      <div class="tag flex">景区</div>
      <div class="tag flex">园区</div>
    </section>

    <section class="five cnt">
      <div class="h1 scroll-fade" v-scrollFade="'active'">
        快速灵活部署，一插即用
      </div>

      <div class="des flex">
        <div class="left">
          <div class="modal">
            <div class="h1 scroll-fade" v-scrollFade="'active'">简易安装</div>
            <div class="h4 scroll-fade" v-scrollFade="'active'">
              只需要连接三相电<br />
              快速实现充电桩部署
            </div>
          </div>
        </div>
        <div class="right">
          <div class="modal">
            <div class="h1 scroll-fade" v-scrollFade="'active'">一站式调试</div>
            <div class="h4 scroll-fade" v-scrollFade="'active'">
              安装，调试，上线，维续，保养<br />
              加速服务效率，大幅缩短运维时间
            </div>
          </div>
        </div>
      </div>

      <div class="des-btm">
        <div class="modal">
          <div class="h1 scroll-fade" v-scrollFade="'active'">
            智能运维<br />
            直观数据全面分析
          </div>
          <div class="h4 scroll-fade" v-scrollFade="'active'">
            光、储、充为一体的SAAS（Service as a
            software）平台设备情况，场站收入，拉<br />新促活，设备运维等各项信息全面展示在统一平台。
          </div>
        </div>
      </div>
      <div class="tips scroll-fade" v-scrollFade="'active'">
        *本网站提供的产品说明展示图以及产品屏幕内内容均为参考示意图，实际情况以实物为准。<br />
        *产品图片、型号、数据、功能、性能、规格参数、用户界面和其他产品信息可能因产品批次或供应商更换等因素而发生变化，誉照有可能对上述内容进行调整改进，具体信息请参照产品实物、产品说明书。<br />
        *除非经特殊说明，本页面中所涉及的对比均为与誉照产品相比较，数据均为誉照实验室数据、设计技术参数或供应商提供数据，全站数据实际情况会因测试软件版本、具体测试环境、具体版本和行业内测量方式的不同，而略有差异，以实际使用体验为准。
      </div>
    </section>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        bg_one: require("@/assets/product/img/chuneng.png"),
      };
    },
  };
</script>
<style lang="less" scoped>
  /deep/.footer-content {
    background: #ffffff !important;
  }
  .h1 {
    font-size: 7.2rem;
    font-family: HarmonyBold;
    font-weight: 700;
    line-height: 9rem;
    cursor: pointer;
  }

  .h2 {
    font-size: 5rem;
    font-family: HarmonyBold;
    font-weight: 500;
    margin: 0 !important;
    cursor: pointer;
  }
  .h2-box {
    height: 17.2rem;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 3rem;
    cursor: pointer;

    &.lineone {
      height: fit-content;
      margin-bottom: 3rem;
    }
  }

  .h3 {
    font-size: 2.4rem;
    font-family: HarmonyLight;
    line-height: 4.9rem;
    cursor: pointer;
  }
  .h4 {
    font-size: 2rem;
    color: #ffffff;
    font-family: HarmonyRegular;
    cursor: pointer;
  }
  .main {
    color: #ffffff;
    background: #0f0f0f;
    padding-bottom: 6rem;

    .top {
      position: relative;
      background: #000000;
      height: 81.8rem;
      overflow: hidden;

      .slogan {
        font-size: 6.4rem;
        font-family: AlibabaPuHuiTi35;
        align-items: center;
        flex-direction: column;
        width: fit-content;
        line-height: 6.4rem;
        cursor: pointer;
        margin-top: 28rem;
        margin-left: 10.4rem;

        em {
          font-style: normal;
          color: #eb4f27;
        }

        .s {
          font-size: 4.8rem;
          font-family: AlibabaPuHuiTi55;
          letter-spacing: 0.17em;
          color: #f5f5f3;
          margin-top: 2.8rem;
        }
      }

      .img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;

        .el-image {
          width: 84.4rem;
          height: 80.7rem;
        }
      }
    }

    .cards {
      flex-wrap: wrap;
      padding: 10.5rem 24.5rem;

      .right {
        flex: 1;
        flex-shrink: 0;
      }

      .card {
        flex: 1;
        flex-shrink: 0;
        background: #000000;
        border-radius: 1.5rem;
        margin-bottom: 3rem;

        &:first-of-type {
          margin-right: 3rem;
        }

        &.one {
          height: 60rem;
        }

        &.two {
          height: 100rem;
        }

        &.three {
          height: 48.5rem;
          margin-right: 0;
        }

        &.four {
          height: 44.4rem;
        }

        .over {
          position: relative;
          text-align: left;
          padding: 8rem 3.3rem;
          z-index: 1;

          .title {
            font-size: 4rem;
            color: #ffffff;
            font-family: HarmonyBold;
            font-weight: 700;
            line-height: 6.3rem;
            margin-bottom: 1.1rem;
            cursor: pointer;
          }

          .subtitle {
            font-size: 2rem;
            color: #fdf3e6;
            line-height: 4rem;
            font-family: HarmonyRegular;
            cursor: pointer;
          }
        }
      }

      .cseven {
        background: radial-gradient(
          154% 83% at 83% 77%,
          rgba(219, 26, 26, 0.35) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    .wall {
      width: 100%;
      height: 85.7rem;
      background: #d8d8d8;
    }

    .digital {
      justify-content: space-between;
      padding: 10.5rem 27rem;

      .item {
        flex-direction: column;

        .nums {
          margin-bottom: 1rem;
          .big {
            font-size: 7.2rem;
            font-family: HarmonyBold;
          }

          .small {
            font-family: AlibabaPuHuiTi45;
            font-weight: 900;
            font-size: 2.4rem;
          }
        }

        .btm {
          font-size: 2.4rem;
          font-family: HarmonyLight;
        }
      }
    }

    .cnt {
      padding: 0 24.5rem;

      .h1 {
        margin: 10.5rem auto 5.9rem;
      }
    }
    .first {
      .h1 {
        margin: 0 auto 10.5rem;
      }
    }
    .area {
      justify-content: space-between;

      .left {
        flex: 1;
        max-width: 68.3rem;
        text-align: left;

        .h2 {
          margin-bottom: 2rem;
        }
      }

      .right {
        width: 61.7rem;
        height: 85.7rem;
        border-radius: 2rem;
        background: #333333;
        flex-shrink: 0;
      }
    }

    .snd {
      .bg {
        height: 68.9rem;
        background: #333333;
        border-radius: 2rem;
      }
    }

    .three {
      .area {
        flex-flow: row-reverse;
      }
    }

    .tags {
      justify-content: space-between;
      padding: 10.5rem 22.6rem 0;

      .tag {
        width: 33.7rem;
        height: 21.5rem;
        align-items: center;
        justify-content: center;
        border-radius: 1.5rem;
        font-size: 5rem;
        background: #000000;
        font-family: HarmonyBold;
      }
    }

    .four {
      .h1 {
        margin-bottom: 0.4rem;
      }
    }

    .five {
      .des {
        justify-content: space-between;

        .left,
        .right {
          width: 70rem;
          height: 60rem;
          border-radius: 15px;
          background: #000000;
          position: relative;
        }

        .left {
          margin-right: 3rem;
        }
      }

      .des-btm {
        height: 40rem;
        border-radius: 15px;
        background: #000000;
        margin-top: 3rem;
        position: relative;

        .h1 {
          margin: 0 0 1.1rem !important;
        }
      }

      .modal {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: left;
        padding: 3.2rem 3.7rem;

        .h1 {
          font-size: 4rem;
          line-height: 6.3rem;
          margin: 7.3rem auto 7.4rem;
        }

        .h4 {
          line-height: 4rem;
        }
      }

      .tips {
        color: #9e9e9e;
        font-family: HarmonyLight;
        font-size: 1.4rem;
        text-align: left;
        line-height: 2.4rem;
        margin-top: 2.5rem;
      }
    }
  }
</style>
